import React from 'react'
import PageNav from '../../components/pageComponents/common/pageNav'
import Wrapper from '../../components/wrapper'
import CompanyJobComponent from '../../components/pageComponents/companyJobs'
import { graphql } from 'gatsby'

const CompanyJobs = ({ location, data }) => {
  const Children = props => {
    return (
      <>
        <PageNav navItem="Company Jobs" />
        <CompanyJobComponent
          employerData={data?.strapiFeaturedEmployer}
          {...props}
        />
      </>
    )
  }
  return (
    <Wrapper location={location} title="Company Jobs | MedReps.com">
      <Children />
    </Wrapper>
  )
}
export default CompanyJobs

export const pageQuery = graphql`
  query ($id: String!) {
    strapiFeaturedEmployer(id: { eq: $id }) {
      id
      logo {
        url
      }
      employer {
        name
        jobs {
          title
          archived_date
          compensation_type
          created_at
          external_redirect
          featured
          primary_function
          product_category
          published_at
          remote_enabled
          slug
          id
          location
        }
      }
    }
  }
`
