import React from 'react'
import { Table, Tag } from 'antd'
import moment from 'moment'
import { Link } from 'gatsby'
import { useSelector } from 'react-redux'
import { useWindowSize } from '../../../../windowResize'
import cookies from 'react-cookies'
import {
  restructureJob,
  dateFormatHandler,
  permissionsHandler,
} from '../../../functions'

const CompanyJobComponent = ({ employerData }) => {
  let _windowSize = useWindowSize()
  let user = cookies.load('user')
  let largeScreen = _windowSize?.width && _windowSize?.width > 1023
  const storeProducts = useSelector(state => state.user).products
  let products = permissionsHandler(storeProducts, user?.role?.id)
  let isAllowed = products?.subscriptionPurchased

  let _companyName = employerData?.employer?.name
  let _companyLogo = employerData?.logo?.url
  let _employerJobs = employerData?.employer?.jobs.filter(
    job =>
      job.published_at &&
      !job?.archived_date &&
      new Date() <= moment(job.published_at).add(30, 'days').toDate()
  )

  const employerJobsCol = [
    {
      title: '',
      dataIndex: 'details',
      key: 'details',
      render: (text, record, index) => {
        let _job = restructureJob(record)
        return (
          <div className="flex">
            {user && isAllowed && (
              <div className="h-10 w-10 rounded shadow flex items-center justify-center">
                <div
                  className="h-8 w-8 bg-cover bg-center bg-no-repeat"
                  style={{
                    backgroundImage: `url(${
                      _companyLogo || '/icons/icon-medical.png'
                    } )`,
                  }}
                />
              </div>
            )}

            <div className="ml-2">
              <p className="text-xs font-bold font-sans mb-0.5">
                {_job?.title || ''}
              </p>
              <div className="flex items-center ">
                <img src="/icons/icon-location-pin.svg" className="mb-0" />
                <span className="text-last text-location leading-0 ml-1">
                  {_job.location}
                </span>
                {record?.suggested ? (
                  <span className="text-tagSize mb-0 ml-1 bg-voyage text-white font-sans px-2 rounded-full h-4 flex items-center">
                    Suggested
                  </span>
                ) : (
                  <div />
                )}
              </div>
            </div>
          </div>
        )
      },
    },
    {
      title: '',
      dataIndex: 'industry',
      key: 'industry',
      render: (text, record, index) => {
        let _job = restructureJob(record)
        return (
          <div>
            <p className="text-location text-last font-sans mb-0">Industry</p>
            <p className="text-last font-sans mb-0">{_job?.product_category}</p>
          </div>
        )
      },
    },
    {
      title: '',
      dataIndex: 'function',
      key: 'function',
      render: (text, record, index) => {
        let _job = restructureJob(record)
        return (
          <div>
            <p className="text-location text-last font-sans mb-0">Function</p>
            <p className="text-last font-sans mb-0">{_job?.primary_function}</p>
          </div>
        )
      },
    },
    {
      title: '',
      dataIndex: 'postedDate',
      key: 'postedDate',
      render: (text, record, index) => {
        let _job = restructureJob(record)
        return (
          <div>
            <p className="text-location text-last font-sans mb-0">
              Posted Date
            </p>
            <p className="text-last font-sans mb-0">
              {dateFormatHandler(_job.published_at)}
            </p>
          </div>
        )
      },
    },
    {
      title: '',
      dataIndex: 'exp',
      key: 'exp',
      render: (text, record, index) => {
        let _job = restructureJob(record)
        return (
          <div>
            <p className="text-location text-last font-sans mb-0">
              Date Expires
            </p>
            <p className="text-last font-sans mb-0">
              {_job.archived_date
                ? dateFormatHandler(_job.archived_date)
                : dateFormatHandler(
                    moment(_job.published_at).add(30, 'days').toDate()
                  )}
            </p>
          </div>
        )
      },
    },

    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (text, record, index) => {
        let _job = restructureJob(record)
        return (
          <Link to={`/job-detail/${_job?.slug}`}>
            <button className="bg-voyage py-1 px-3 font-sans text-white text-last font-bold rounded mr-2">
              View
            </button>
          </Link>
        )
      },
      responsive: ['lg'],
    },
  ]
  const employerJobsColMobile = [
    {
      title: '',
      dataIndex: 'mobile',
      key: 'mobile',
      render: (text, record, index) => {
        let _job = restructureJob(record)
        return (
          <div className="flex flex-col">
            <div className="flex w-full">
              {user && isAllowed && (
                <div
                  className="h-12 w-12 bg-cover bg-center bg-no-repeat rounded shadow-lg hidden lg:block"
                  style={{
                    backgroundImage: `url(${
                      _companyLogo || '/icons/icon-medical.png'
                    } )`,
                  }}
                />
              )}
              <div>
                <div className="flex flex-col">
                  <p className="text-[14px] opacity-90 font-bold font-sans mb-0.5 leading-0">
                    {_job?.title || ''}
                  </p>
                  <div className="flex items-center pt-2">
                    <img
                      src="/icons/icon-location-pin.svg"
                      className="mb-0 h-[12px] mr-1"
                    />
                    <span className="text-[11px] font-semibold opacity-40 leading-0">
                      {_job?.location}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-between mt-3">
              <div className="flex flex-col w-[30%]">
                <p className="text-[12px] text-location font-normal opacity-70 leading-0 m-0 pb-1">
                  Industry
                </p>
                <div className="flex items-center">
                  <span className="text-[11px] font-semibold opacity-90 leading-0">
                    {_job?.product_category}
                  </span>
                </div>
              </div>
              <div className="flex flex-col w-[30%]">
                <p className="text-[12px] text-location font-normal opacity-70 leading-0 m-0 pb-1">
                  Function
                </p>
                <div className="flex items-center">
                  <span className="text-[11px] font-semibold opacity-90 leading-0">
                    {_job?.primary_function}
                  </span>
                </div>
              </div>
              <div className="flex flex-col w-[30%]">
                <p className="text-[12px] text-location font-normal opacity-70 leading-0 m-0 pb-1">
                  Date Expires
                </p>
                <div className="flex items-center">
                  <span className="text-[11px] font-semibold opacity-90 leading-0">
                    {_job.archived_date
                      ? dateFormatHandler(_job.archived_date)
                      : dateFormatHandler(
                          moment(_job.published_at).add(30, 'days').toDate()
                        )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )
      },
    },
  ]

  return (
    <div>
      <div className="container mx-auto">
        <div className="px-2 lg:px-6 lg:px-0 rounded-lg bg-white mt-4">
          <div className="flex py-4 border-b border-bgSecondaryButton justify-between">
            <div className="flex items-center">
              <img src="/icons/icon-application.svg" className="mb-0" />
              <h1 className="font-playfair text-xl font-normal mb-0 ml-4">
                {user && isAllowed ? _companyName : 'Company'} Jobs
              </h1>
            </div>
          </div>

          <Table
            onRow={(record, rowIndex) => {
              return {
                onClick: event => {
                  window.open('/job-detail/' + record?.slug, '_self')
                },
              }
            }}
            className="hide-table-header-background hide-table-header-cols-border table-with-pagination"
            columns={largeScreen ? employerJobsCol : employerJobsColMobile}
            dataSource={_employerJobs}
            bordered={false}
          />
        </div>
      </div>
    </div>
  )
}
export default CompanyJobComponent
